import React, { useEffect, useState } from "react";


const TablePagination = ({ range, setPage, page, slice }) => {
    const [currentValue, setCurrentValue] = useState(0);
    useEffect(() => {
        if (slice.length < 1 && page !== 1) {
            setPage(page - 1);
        }
    }, [slice, page, setPage]);
    return (

     <>   <ul className="pagination justify-content-center m-t-20 font-size-5  font-weight-bold">
            <li className="page-item pointer" onClick={() => { setCurrentValue(0); setPage(1); }}><a className={`page-link ${page === 1 ? " " : ""
                }`}  >{"<<"}</a></li>

            {range.length <= 5 &&
                <>  <li className="page-item pointer" onClick={() => { if (page == (currentValue * 5) + 1 && page != 1) setCurrentValue(currentValue - 1); if (page != 1) setPage(page - 1) }}><a className="page-link"  >{"<"}</a></li>
                    {range.map((el, index) => (
                        <>
                            <li key={index} className={`page-item pointer `} onClick={() => setPage(el)}><a className={`page-link ${page === el ? "active" : ""
                                }`}  >{el}</a></li>
                        </>
                    ))}
                    <li className="page-item pointer" onClick={() => { if (page == (currentValue * 5) + 5 && page != range.length) setCurrentValue(currentValue + 1); if (page != range.length) setPage(page + 1) }}><a className="page-link" href="javascript:void(0);">{">"}</a></li>
                </>
            }
            {range.length > 5 &&
                <>
                {currentValue > 0 && < li className={`page-item pointer  ${page === (currentValue * 5) + 1 ? "active bg-green" : ""}`} onClick={() => { setCurrentValue(currentValue - 1); setPage(((currentValue - 1) * 5) + 1) }}><a className={`page-link  `}  >...</a></li>}
                {(currentValue * 5) + 1 <= range.length && <li className={`page-item pointer  ${page === (currentValue * 5) + 1 ? "active bg-green" : ""}`} onClick={() => setPage((currentValue * 5) + 1)}><a className={`page-link ${page === (currentValue * 5) + 1 ? "active text-green-2" : ""
                        }`}  >{(currentValue * 5) + 1}</a></li>}
                {(currentValue * 5) + 2 <= range.length && <li className={`page-item pointer ${page === (currentValue * 5) + 2 ? "active bg-green":""} `} onClick={() => setPage((currentValue * 5) + 2)}><a className={`page-link ${page === (currentValue * 5) + 2 ? "active text-green-2" : ""
                        }`}  >{(currentValue * 5) + 2}</a></li>}
                {(currentValue * 5) + 3 <= range.length && <li className={`page-item pointer  ${page === (currentValue * 5) + 3 ? "active bg-green" : ""}`} onClick={() => setPage((currentValue * 5) + 3)}><a className={`page-link ${page === (currentValue * 5) + 3 ? "active text-green-2" : ""
                        }`}  >{(currentValue * 5) + 3}</a></li>}
                {(currentValue * 5) + 4 <= range.length && <li className={`page-item pointer  ${page === (currentValue * 5) + 4 ? "active bg-green" : ""}`} onClick={() => setPage((currentValue * 5) + 4)}><a className={`page-link ${page === (currentValue * 5) + 4 ? "active text-green-2" : ""
                        }`}  >{(currentValue * 5) + 4}</a></li>}
                {(currentValue * 5) + 5 <= range.length && <li className={`page-item pointer  ${page === (currentValue * 5) + 5 ? "active bg-green" : ""}`} onClick={() => setPage((currentValue * 5) + 5)}><a className={`page-link ${page === (currentValue * 5) + 5 ? "active text-green-2" : ""
                        }`}  >{(currentValue * 5) + 5}</a></li>}

                {(currentValue * 5) + 5 < range.length && < li className={`page-item pointer   `} onClick={() => { setCurrentValue(currentValue + 1); setPage(((currentValue + 1) * 5) + 1) }}><a className={`page-link  `}  >... {range.length}</a></li>}

                </>
            }


            <li className="page-item pointer" onClick={() => { setCurrentValue((range.length % 5 == 0) ? (range.length / 5) - 1 : ((range.length - (range.length % 5)) / 5)); setPage(range.length); }}><a className="page-link" href="javascript:void(0);">{">>"}</a></li>
        </ul>
            <div className="m-20"> &nbsp;</div>   </>

    );
};

export default TablePagination;