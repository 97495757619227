import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../../context/GlobalContext";
import { AdminUsers, AdminStatusUpdate, FlagUser, CreateAlert, AdminDeleteS3Object } from "../../utils/apiCalls";
import ReactCountryFlag from "react-country-flag";
import ModalFlagReason from "./ModalFlagReason";

import { getMonthDateYear } from "../../utils/addDays";
import TablePagination from "../../components/Table/TablePagination";
import tableHelper from "../../components/Table/tableHelper";

export default function Clients(props) {

    const gContext = useContext(GlobalContext);
    const s3URL = process.env.REACT_APP_AWS_S3_URL; 
    const [experts, setExperts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentValue, setCurrentValue] = useState({ index: null, id: null, code: "" });
    const [currentUser, setCurrentUser] = useState(null);
    const [currentFile, setCurrentFile] = useState("");
    const [page, setPage] = useState(1);
    const { slice, range } = tableHelper(experts, page, gContext.paginationSize);
    function sociallink(link) {

        var reg = new RegExp("^(http|https)://", "i");

        var match = reg.test(link);
        //alert(link);
        if (match == true) {
            return link;
        }
        else {
            link = 'https://' + link;
            return link;
        }



    }



    async function LoadClient() {
        try {
            gContext.setLoaderModalVisible(true);
            const result = await AdminUsers("new", 2);
            if (result.data.adminUsers.responseData.code == "200") {

                let data = JSON.parse(result.data.adminUsers.responseData.message);
 
                if (data.length > 0) {
                    let list = data.filter(item => item["delete"] == false);
                    setExperts(list);
                }
                else {
                    gContext.setLoaderModalVisible(false);
                }

            } else {
                gContext.setLoaderModalVisible(false);
            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
        }
    }
    async function submitflag(flagId) {
        if (currentUser) {
            try {
                gContext.setLoaderModalVisible(true);
                const result = await FlagUser(flagId, currentUser, 2);

                if (result.data.flagUser && result.data.flagUser.responseData.code == "200") {
                    LoadClient();
                    gContext.setLoaderModalVisible(false);

                } else {
                    gContext.setLoaderModalVisible(false);
                }

                gContext.setLoaderModalVisible(false);
            }
            catch (e) {
                gContext.setLoaderModalVisible(false);
            }
        }
        else
            setCurrentUser(null);
    }
    function toggleModal(userId) {
        setCurrentUser(userId);
        setShowModal(!showModal);
    }
    async function UpdateStatus(status, statusType, userId, reason) {
        try {
            
            gContext.setLoaderModalVisible(true);
            const result = await AdminStatusUpdate(status, statusType, userId, 2, reason);
            
            if (result.data.adminStatusUpdate && result.data.adminStatusUpdate.responseData.code == "200")
            { 
                LoadClient(); 

                if (status == "approve")
                {
                    let message = (statusType == "cert") ? "Congratulation! Your certificate is approved by Syncuppro." : "Congratulation! Your website is approved by Syncuppro.";
                    await CreateAlert({ id: null, alert: message, alertType: "Account", display: true, status: 'N', toTypeId: 2, toUserId: userId });
 
                    if (statusType == "cert" && currentFile != "") {
                        await AdminDeleteS3Object(currentFile);
                    }

                }
                else
                {
                    let message = (statusType == "cert") ? "Oh No, your Certificate is rejected. Please upload a valid Certificate. Reason:" + reason : "Oh No, your website URL is rejected. Please add a valid website URL. Reason:" + reason;
                    await CreateAlert({ id: null, alert: message, alertType: "Account", display: true, status: 'N', toTypeId: 2, toUserId: userId });
                }

            }
            else {
                gContext.setLoaderModalVisible(false);
            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
        }
    }
    const statusUpdate = (status, statusType, userId) => {
        if (userId) {
            if (status == "unflag")
                setCurrentUser(userId);
            gContext.setconfirmModal({ header: "", ["text"]: "Do you want to " + status + "?", ["confirmed"]: false, id: userId, showButton: true, mode: status, code: statusType, reason: ""});
            gContext.toggleConfirmModal();
        }
    }

    useEffect(() => {
        if (gContext.confirmModal.confirmed) {

            if (gContext.confirmModal.mode == "approve") {
                UpdateStatus("approve", gContext.confirmModal.code, gContext.confirmModal.id, gContext.confirmModal.reason );
            }
            else if (gContext.confirmModal.mode == "reject") {
                UpdateStatus("reject", gContext.confirmModal.code, gContext.confirmModal.id, gContext.confirmModal.reason );
            }
            else if (gContext.confirmModal.mode == "unflag") {
                submitflag(-1);
                setCurrentUser(null);
            }
            gContext.setconfirmModal({
                ...gContext.confirmModal, ["confirmed"]: false, ["id"]: null
            });

        }
        else { setCurrentValue({ index: null, id: null }); }
    }, [gContext.confirmModal]);

    useEffect(() => {
        document.title = "New Clients | Syncuppro";
        LoadClient();
    }, []);
    return (
        <>
            <div
                className="dashboard-main-container mt-24 mt-lg-31 min-h-100vh"
                id="dashboard-body"
            >
                <div className="container">
                    <div className="mb-15 mb-lg-10">
                        <div className="row">
                            <div className="font-size-4 font-weight-semibold  bg-athens  w-100  text-green-2  pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">

                            </div>
                            <div className="col-xxxl-12  ">


                                <div className="contact-form bg-white shadow-8 ">

                                    <div className="d-flex font-size-6 font-weight-semibold mb-11   bg-gray-opacity-3 pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5 w-100">
                                        <div className="w-50">  <span>New Clients  ({experts.length})</span> </div> <div className="w-50 text-right"> <span className="rounded bg-green pointer font-size-6 w-10 text-right  " onClick={() => { LoadClient(); }}> <i className="fa fa-refresh m-5 text-white"></i></span>    </div>
                                    </div>


                                    <div className="col-lg-12">
                                        <label
                                            htmlFor="namedash"
                                            className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                        >
                                        </label>
                                        <div className="bg-white  pt-5   pb-9 px-2">
                                            <div className="table-responsive ">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr className="bg-gray-opacity-2 border border-color-2 py-5">
                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Company
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                E-mail
                                                            </th>                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Reg Date
                        </th>    <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Location
                        </th> <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Website 
                        </th>
                                                            <th
                                                                scope="col"
                                                                className="pl-5  table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Certificate
                        </th>

                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5 mr-0" 
                                                            ></th> 


                                                        </tr> 
                                                    </thead>
                                                    <tbody>
                                                        {slice && slice.map((data, index) => {
                                                            console.log("slice", slice); 
                                                            return (
                                                                <tr className="border border-color-2" key={index}>

                                                                    <td className="table-y-middle py-5 min-width-px-135">
                                                                        <a className="media min-width-px-235 align-items-center pointer" target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_DOMAIN + 'adminclient/' + data.sub}  >
                                                                            <div className="circle-36 mr-6">
                                                                                <img src={data.logo ? s3URL + data.logo : "assets/image/avatar.jpg"} alt="" className=" circle-36 w-100" onError={(img) => { img.target.src = 'assets/image/avatar.jpg'; }} />
                                                                            </div>
                                                                            {(data.company && data.company != "") ? <>   <h4 data-toggle="tooltip" style={{ wordWrap: "break-word" }} title={data.company} className="font-size-4 mb-0 font-weight-semibold text-black-2 pointer ellipsis min-width-px-185"   >
                                                                                {data.company}
                                                                            </h4> <i className="fa fa-arrow-up-right-from-square font-size-3 text-green pl-5"></i></>
                                                                                : <>   <h4 data-toggle="tooltip" style={{ wordWrap: "break-word" }} title={data.email ? data.email.split('@')[0] : "N/A"} className="font-size-4 mb-0 font-weight-semibold text-black-2 pointer ellipsis min-width-px-185"   >
                                                                                    {data.email ? data.email.split('@')[0] : "N/A"}
                                                                                </h4> <i className="fa fa-arrow-up-right-from-square font-size-3 text-green pl-5"></i></>}
                                                                        </a>
                                                                    </td>
                                                                    <td className="table-y-top py-5 min-width-px-135">
                                                                        {data.email}
                                                                    </td> 
                                                                    <td className="table-y-middle py-5 min-width-px-125">
                                                                        	
                                                                        {getMonthDateYear(data.datecreated)}
                                                                    </td>
                                                                    <td className="table-y-top py-5 min-width-px-235 ">
                                                                        {data.city ? <h3 data-toggle="tooltip" title={data.city + ',' + data.state + ',' + data.countrycode} className=" ellipsis min-width-px-184 font-size-4 font-weight-normal text-black-2   mb-0">
                                                                            <ReactCountryFlag
                                                                                countryCode={data.countrycode}
                                                                                svg
                                                                                style={{
                                                                                    width: '2em',
                                                                                    height: '1em', wordWrap: "break-word"
                                                                                }}
                                                                                title={data.countrycode}
                                                                            />         {data.city + ' '}, {data.state} ,{' ' + data.countrycode}
                                                                        </h3> : <span>N/A</span>}
                                                                    </td>
                                                                    <td className="table-y-middle py-5 min-width-px-185">{data.website && data.website != "null" &&
                                                                        <div className="row"><div className="col-12 text-center">
                                                                            
                                                                            <a href={sociallink(data.website)} rel="noopener noreferrer" className="text-green-2 font-weight-semibold" target="_blank" data-toggle="tooltip" title="Click here to view.">Website</a>
                                                                           
                                                                            <button disabled={data.idverified == true && data.profilecomplete == true} className={`border-0 mr-1  font-size-4 ml-5 font-weight-bold justify-content-md-end  px-2 rounded-5  text-green-2  border-width-1 bg-gray-opacity-2   shadow-8 text-uppercase pointer  ${(data.idverified == true && data.profilecomplete == true) ? 'text-gray bg-gray-opacity-4' : 'text-green-2 bg-gray-opacity-2'}`} data-toggle="tooltip" title="Click to approve." onClick={() => statusUpdate("approve", "site", data.id)} >
                                                                        <i className="fa fa-check "></i>
                                                                            </button>
                                                                            <button    className={`border-0 mr-1  font-size-4  font-weight-bold justify-content-md-end  px-2 rounded-5  text-green-2  border-width-1 bg-gray-opacity-2   shadow-8 text-uppercase pointer  ${(data.idverified == false && data.profilecomplete == false) ? 'text-gray bg-gray-opacity-4' : 'text-red bg-gray-opacity-2'}`} data-toggle="tooltip" title={(data.idverified == false && data.profilecomplete == false) ? "Rejected" : "Click to Reject"} onClick={() => statusUpdate("reject", "site", data.id)} >
                                                                            <i className="fa fa-times "></i>
                                                                        </button>   </div>

                                                                    </div>  }</td>
                                                                    <td className="table-y-middle py-5 min-width-px-210  ">
                                                                        {data.certfilename && data.certfilename !="null" &&
                                                                            <div className="row"><div className="col-12 text-center"> {(data.idverified == true && data.profilecomplete == true) ? "Certificate" : <a href={(data.idverified == true && data.profilecomplete == true) ? "#" : s3URL + data.certfilename} rel="noopener noreferrer" className="text-green-2 font-weight-semibold" target="_blank" data-toggle="tooltip" title="Click here to view.">Certificate</a>}
                                                                                {(data.idverified != true && data.profilecomplete != true) && <> <button disabled={data.idverified == true && data.profilecomplete == true} className={`border-0 mr-1  font-size-4 ml-5 font-weight-bold justify-content-md-end  px-2 rounded-5  text-green-2  border-width-1 bg-gray-opacity-2   shadow-8 text-uppercase pointer  ${(data.idverified == true && data.profilecomplete == true) ? 'text-gray bg-gray-opacity-4' : 'text-green-2 bg-gray-opacity-2'}`} data-toggle="tooltip" title={(data.idverified == true && data.profilecomplete == true) ? "Approved" : "Click to Approve"} onClick={() => { setCurrentFile((data.certfilename && data.certfilename != "null") ? data.certfilename : ""); statusUpdate("approve", "cert", data.id); }} >
                                                                                    <i className="fa fa-check "></i>
                                                                                </button>    <button className={`border-0 mr-1  font-size-4  font-weight-bold justify-content-md-end  px-2 rounded-5  text-green-2  border-width-1 bg-gray-opacity-2   shadow-8 text-uppercase pointer  ${((data.idverified == false && data.profilecomplete == false)) ? 'text-gray bg-gray-opacity-4' : 'text-red bg-gray-opacity-2'}`} data-toggle="tooltip" title={(data.idverified == false && data.profilecomplete == false) ? "Rejected" : "Click to Reject"} onClick={() => statusUpdate("reject", "cert", data.id)} >
                                                                                        <i className="fa fa-times "></i>
                                                                                    </button> </>}  </div></div>
                                                                            
                                                                           }

                                                                    </td> 
                                                                    <td className="table-y-middle  w-100 pr-0 media text-right justify-content-md-end">
                                                                      
                                                                        <div data-toggle="tooltip " className="text-right" > {!data.flagged && <button className="border font-size-3 font-weight-bold text-red px-5 rounded-5  mr-1 border-width-1 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer" data-toggle="tooltip" title="Click to flag." onClick={(e) => toggleModal(data.id)}  >
                                                                            Flag
                              </button>}
                                                                            {data.flagged && <button className="border font-size-3 font-weight-bold text-green-2 px-5 rounded-5  mr-1 border-width-1 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer" data-toggle="tooltip" title="Click to unflag." onClick={() => statusUpdate("unflag", "unflag", data.id)}>
                                                                                UnFlag
                              </button>}</div>
                                                                    </td>

                                                                </tr>)
                                                        })}


                                                    </tbody>
                                                </table>
                                            </div>
                                        </div></div> 
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <TablePagination range={range} slice={slice} setPage={setPage} page={page} />
                <ModalFlagReason submitflag={submitflag} modalvisible={showModal} togglemodal={toggleModal} />
            </div>
        </>
    );
};