import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../../context/GlobalContext";
import { AdminUsers, AdminStatusUpdate, AdminExpCertStatus, AdminDeleteS3Object, FlagUser, CreateAlert } from "../../utils/apiCalls";
import ReactCountryFlag from "react-country-flag";
import ModalFlagReason from "./ModalFlagReason";
import { getMonthDateYear } from "../../utils/addDays";
import TablePagination from "../../components/Table/TablePagination";
import tableHelper from "../../components/Table/tableHelper";

export default function Experts(props) {

    const gContext = useContext(GlobalContext);
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const [experts, setExperts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentValue, setCurrentValue] = useState({ index: null, id: null, code: "" });
    const [currentUser, setCurrentUser] = useState(null);
    const [currentFile, setCurrentFile] = useState("");
    const [page, setPage] = useState(1);
    const { slice, range } = tableHelper(experts, page, gContext.paginationSize);

    function sociallink(link) {

        var reg = new RegExp("^(http|https)://", "i");

        var match = reg.test(link);
        //alert(link);
        if (match == true) {
            return link;
        }
        else {
            link = 'https://' + link;
            return link;
        }



    }


    async function LoadExpert() {
        try {
            gContext.setLoaderModalVisible(true);
            const result = await AdminUsers("new", 1);
            if (result.data.adminUsers && result.data.adminUsers.responseData.code == "200") {
                let data = JSON.parse(result.data.adminUsers.responseData.message);
                
                if (data.length > 0) {
                    let list = data.filter(item => item["delete"] == false);
                    setExperts(list);
                }
                else {
                    gContext.setLoaderModalVisible(false);
                }
            } else {
                gContext.setLoaderModalVisible(false);
            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
        }
    }
    async function UpdateStatus(status, statusType, userId, reason) {
        try {
            gContext.setLoaderModalVisible(true);
            const result = await AdminStatusUpdate(status, statusType, userId, 1, reason);
            if (result.data.adminStatusUpdate && result.data.adminStatusUpdate.responseData.code == "200") { 
                LoadExpert(); 


                if (status == "approve") {
                    let message = (statusType == "id") ? "Congratulations! Your Photo id is approved." : "Congratulations! Your Certificate is approved.";
                    if (statusType == "id")
                        await CreateAlert({ id: null, alert: message, alertType: "Account", display: true, status: 'N', toTypeId: 1, toUserId: userId });

                    if (currentFile != "")
                    await AdminDeleteS3Object(currentFile);

                }
                else {
                    let message = (statusType == "id") ? "Oh No, your photo id is rejected. Please upload a different photo id. Reason: " + reason : "Oh No, your Certificate is rejected. Please upload a valid Certificate. Reason: " + reason;
                    if (statusType == "id")
                    await CreateAlert({ id: null, alert: message, alertType: "Account", display: true, status: 'N', toTypeId: 1, toUserId: userId });
                }

            } else {
                gContext.setLoaderModalVisible(false);
            } 
            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
        }
    }


    async function UpdateCertStatus(status,statusType, userId,reason,certId) {
        try {
            gContext.setLoaderModalVisible(true);
            
            const result = await AdminExpCertStatus(certId, userId, status);
            
           
            if (result.data.adminUpdateExpCertStatus && result.data.adminUpdateExpCertStatus.responseData.code == "200") {
                LoadExpert();
                
                let message = (status && status.toString() == "true") ? "Congratulations! Your Certificate is approved." : "Oh No, your Certificate is rejected. Please upload a valid Certificate. Reason:" + reason;

                await CreateAlert({ id: null, alert: message, alertType: "Account", display: true, status: 'N', toTypeId: 1, toUserId: userId });
                 
                if (status && status.toString() == "true" && currentFile !="")
                    await AdminDeleteS3Object(currentFile);

            } else {
                gContext.setLoaderModalVisible(false);
            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
        }
    }


    async function submitflag(flagId) {
        if (currentUser) {
            try {
                gContext.setLoaderModalVisible(true);
                const result = await FlagUser(flagId, currentUser, 1);

                if (result.data.flagUser && result.data.flagUser.responseData.code == "200") {
                    LoadExpert();
                    gContext.setLoaderModalVisible(false);

                } else {
                    gContext.setLoaderModalVisible(false);
                }

                gContext.setLoaderModalVisible(false);
            }
            catch (e) {
                gContext.setLoaderModalVisible(false);
            }
        }
        else
            setCurrentUser(null);
    }
    function toggleModal(userId) {
        setCurrentUser(userId);
        setShowModal(!showModal);
    }
    const statusUpdate = (status, statusType, userId) => {
        
        if (userId) {
            if (status == "unflag")
                setCurrentUser(userId);
            gContext.setconfirmModal({ header: " ", ["text"]: "Do you want to " + status + "?", ["confirmed"]: false,   id: userId, showButton: true, mode: status, code: statusType,reason:"" });
            gContext.toggleConfirmModal();
        } 
    }

    const certStatusUpdate = (certstatus,status,statusType, userId,certId) => {
        //certStatusUpdate("true","approve", "cert", data.id,cert.id)
        
        if (userId) {
            if (status == "unflag")
                setCurrentUser(userId);
            gContext.setconfirmModal({ header: " ", ["text"]: "Do you want to " + status + "?", ["confirmed"]: false, certstatus:certstatus,  id: userId, showButton: true, mode: status, code: statusType,reason:"",certid:certId});
            gContext.toggleConfirmModal();
        } 
    }

    useEffect(() => {
        if (gContext.confirmModal.confirmed) { 
          
             if (gContext.confirmModal.mode == "approve") {
                 UpdateStatus("approve", gContext.confirmModal.code, gContext.confirmModal.id, gContext.confirmModal.reason ); 
            }
            else if (gContext.confirmModal.mode == "reject") {
                 UpdateStatus("reject", gContext.confirmModal.code, gContext.confirmModal.id, gContext.confirmModal.reason); 
            }
             else if (gContext.confirmModal.mode == "unflag") {
                 submitflag(-1);
                 setCurrentUser(null);
             }
        

             if (gContext.confirmModal.mode == "approve" && gContext.confirmModal.code == "cert") {
                UpdateCertStatus(gContext.confirmModal.certstatus, gContext.confirmModal.code, gContext.confirmModal.id, gContext.confirmModal.reason ,gContext.confirmModal.certid); 
           }
           else if (gContext.confirmModal.mode == "reject" && gContext.confirmModal.code == "cert") {
            UpdateCertStatus(gContext.confirmModal.certstatus, gContext.confirmModal.code, gContext.confirmModal.id, gContext.confirmModal.reason,gContext.confirmModal.certid); 
           }
            gContext.setconfirmModal({
                ...gContext.confirmModal, ["confirmed"]: false, ["id"]: null
            });
            
        }
        else { setCurrentValue({ index: null, id: null }); }
    }, [gContext.confirmModal]);
    useEffect(() => { 
        document.title = "New Experts | Syncuppro";
        LoadExpert(); 
    }, []);
    return (
        <>
            <div
                className="dashboard-main-container mt-24 mt-lg-31 min-h-100vh"
                id="dashboard-body"
            >
                <div className="container">
                    <div className="mb-15 mb-lg-10">
                        <div className="row">
                            <div className="font-size-4 font-weight-semibold  bg-athens  w-100  text-green-2  pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">
                                 
                            </div>
                            <div className="col-xxxl-12  "> 
                                <div className="contact-form bg-white shadow-8 ">

                                    <div className="d-flex font-size-6 font-weight-semibold mb-11   bg-gray-opacity-3 pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5 w-100">
                                        <div className="w-50">  <span>New Experts  ({experts.length})</span> </div> <div className="w-50 text-right"> <span className="rounded bg-green pointer font-size-6 w-10 text-right  " onClick={() => { LoadExpert(); }}> <i className="fa fa-refresh m-5 text-white"></i></span>    </div>
                                    </div>


                                    <div className="col-lg-12">
                                        <label
                                            htmlFor="namedash"
                                            className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                        >
                                        </label>
                                        <div className="bg-white  pt-5   pb-9 px-2">
                                            <div className="table-responsive ">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr className="bg-gray-opacity-2 border border-color-2 py-5">
                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Name
                                                            </th> <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                E-mail
                                                            </th>  <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Reg Date
                        </th>   <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                                            >
                                                                Location
                        </th> <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5 "
                                                            >
                                                               Photo ID
                        </th>  
                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5 "
                                                            >
                                                                Certificates
                        </th>  
                                                            <th
                                                                scope="col"
                                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5 text-center"
                                                            >
                                                            
                        </th>  
                                                           


                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {slice && slice.map((data, index) => {

                                                            return (
                                                                <tr className="border border-color-2" key={index}> 

                                                                    <td className="table-y-top py-5 min-width-px-135">
                                                                        <a className="media min-width-px-235 align-items-center pointer" target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_DOMAIN+'adminexpert/'+data.sub}  >
                                                                            <div className="circle-36 mr-6">
                                                                                <img src={data.avatar ? s3URL + data.avatar : "assets/image/avatar.jpg"} alt="" className=" circle-36 w-100" onError={(img) => { img.target.src = 'assets/image/avatar.jpg'; }} />
                                                                            </div>
                                                                            {data.firstname ? <> <h4 data-toggle="tooltip" style={{ wordWrap: "break-word" }} title={data.firstname + ' ' + data.lastname} className="ellipsis min-width-px-185 font-size-4 mb-0 font-weight-semibold text-black-2 pointer"   >
                                                                                {data.firstname + ' ' + data.lastname}
                                                                            </h4><i className="fa fa-arrow-up-right-from-square font-size-3 text-green pl-5"></i></> : <> <h4 data-toggle="tooltip" style={{ wordWrap: "break-word" }} title={data.email ? data.email.split('@')[0] : "N/A"} className="ellipsis min-width-px-185 font-size-4 mb-0 font-weight-semibold text-black-2 pointer"   >
                                                                                    {data.email ? data.email.split('@')[0] : "N/A"}
                                                                            </h4><i className="fa fa-arrow-up-right-from-square font-size-3 text-green pl-5"></i></>}

                                                                        </a>
                                                                    </td> 
                                                                    <td className="table-y-top py-5 min-width-px-135">
                                                                        { data.email}
                                                                    </td> 
                                                                     <td  className="table-y-top py-5  " >

                                                                      <div style={{
                                                                                    width: '120px',
                                                                                    
                                                                                }}>  {getMonthDateYear(data.datecreated)} </div>
                                                                    </td>    
                                                                    <td className="table-y-top py-5 min-width-px-235 ">
                                                                        {data.city ? <h3 data-toggle="tooltip" title={data.city + ',' + data.state + ',' + data.countrycode} className=" ellipsis min-width-px-184 font-size-4 font-weight-normal text-black-2   mb-0">
                                                                            <ReactCountryFlag
                                                                                countryCode={data.countrycode}
                                                                                svg
                                                                                style={{
                                                                                    width: '2em',
                                                                                    height: '1em', wordWrap: "break-word"
                                                                                }}
                                                                                title={data.countrycode}
                                                                            />         {data.city + ' '}, {data.state} ,{' ' + data.countrycode}
                                                                        </h3> : <span>N/A</span>}
                                                                    </td>
                                                                    <td className="table-y-top py-5 min-width-px-185 text-center">
                                                                        <div className="row"><div className="col-12 text-center media">
                                                                            {(data.photo && data.photo != "null") && <>
                                                                                {(data.idverified == true) &&
                                                                                    <span className="text-gray font-weight-semibold" data-toggle="tooltip" title="Verified"  >Photo ID <i className="fa fa-check text-green" data-toggle="tooltip" title="Verified"></i></span>
                                                                                }
                                                                                {!data.idverified &&
                                                                                    <a href={s3URL + data.photo} rel="noopener noreferrer" className="text-green-2 font-weight-semibold" target="_blank" data-toggle="tooltip" title="Click here to view.">Photo ID</a>
                                                                                }
                                                                                {data.idverified != true && <>
                                                                                <button disabled={data.idverified == true} className={`border-0 mr-1  font-size-4 ml-5 font-weight-bold justify-content-md-end  px-2 rounded-5  text-green-2  border-width-1 bg-gray-opacity-2   shadow-8 text-uppercase pointer  ${(data.idverified == true) ? 'text-gray bg-gray-opacity-4' : 'text-green-2 bg-gray-opacity-2'}`} data-toggle="tooltip" title={data.idverified == true ? "Approved" : "Click to Approve"} onClick={() => { setCurrentFile(data.photo); statusUpdate("approve", "id", data.id); }} >
                                                                                    <i className="fa fa-check "></i>
                                                                                </button>    <button disabled={data.idverified == false} className={`border-0 mr-1  font-size-4  font-weight-bold justify-content-md-end  px-2 rounded-5  text-green-2  border-width-1 bg-gray-opacity-2   shadow-8 text-uppercase pointer  ${(data.idverified == false) ? 'text-gray bg-gray-opacity-4' : 'text-red bg-gray-opacity-2'}`} data-toggle="tooltip" title={(data.idverified == false) ? "Rejected" : "Click to Reject"} onClick={() => statusUpdate("reject", "id", data.id)} >
                                                                                    <i className="fa fa-times "></i>
                                                                                </button></>} </>} </div></div>
                                                                         


                                                                    </td> 

                                                                    <td className="table-y-top py-5 min-width-px-235 text-center ">
                                                                         {(data.expertCertificate) && data.expertCertificate.map((cert, index) => {

                                                                            return (
                                                                                <div className="row">{(cert.certname) && <>
                                                                                    <div className="col-8  text-left">
                                                                                        {cert.approved == true && <> {(cert.certfilename) ? <span className="text-gray font-weight-semibold" data-toggle="tooltip" title="Verified" >{cert.certname}<i className="fa fa-check text-green" ></i></span> :
                                                                                            <a href={(cert.certfilename) ? '#' : ((cert.certurl) ? sociallink(cert.certurl) : "")}
                                                                                                rel="noopener noreferrer" className="text-gray font-weight-semibold max-width-px-170 ellipsis"
                                                                                                style={{
                                                                                                    width: '2em',
                                                                                                    height: '2em', wordWrap: "break-word"
                                                                                                }}
                                                                                                target="_blank" data-toggle="tooltip" title={cert.certname}>{cert.certname}</a>}</>}

                                                                                        {(!cert.approved || cert.approved == false) && <a href={(cert.certfilename) ? s3URL + cert.certfilename : ((cert.certurl) ? sociallink(cert.certurl) : "")}
                                                                                            rel="noopener noreferrer" className="text-green-2 font-weight-semibold max-width-px-170 ellipsis"
                                                                                            style={{
                                                                                                width: '2em',
                                                                                                height: '2em', wordWrap: "break-word"
                                                                                            }}
                                                                                            target="_blank" data-toggle="tooltip" title={cert.certname}>{cert.certname}</a>}
                                                                                    </div>

                                                                                    {cert.approved != true && <div className="col-4 media  text-left">  <button disabled={cert.approved == true} className={`border-0 mr-1  font-size-4 ml-5 font-weight-bold justify-content-md-end  px-2 rounded-5  text-green-2  border-width-1 bg-gray-opacity-2   shadow-8 text-uppercase pointer  ${(cert.approved == true) ? 'text-gray bg-gray-opacity-4' : 'text-green-2 bg-gray-opacity-2'}`} data-toggle="tooltip" title={cert.approved == true ? "Approved" : "Click to Approve"} onClick={() => {  setCurrentFile(cert.certfilename ? cert.certfilename:""); certStatusUpdate("true", "approve", "cert", data.id, cert.id); }} >
                                                                                        <i className="fa fa-check "></i>
                                                                                    </button>    <button disabled={cert.approved==false} className={`border-0 mr-1  font-size-4  font-weight-bold justify-content-md-end  px-2 rounded-5  text-green-2  border-width-1 bg-gray-opacity-2   shadow-8 text-uppercase pointer  ${(cert.approved==false) ? 'text-gray bg-gray-opacity-4' : 'text-red bg-gray-opacity-2'}`} data-toggle="tooltip" title={cert.approved ==false ? "Rejected" : "Click to Reject"} onClick={() => certStatusUpdate("false","reject", "cert", data.id,cert.id)} >
                                                                                            <i className="fa fa-times "></i>
                                                                                        </button> </div> } </>}</div>)
                                                                        })}
                                                                        
                                                                    </td> 
                                                                   
                                                                 

                                                                    <td className="table-y-top  w-100 pr-0 media text-right justify-content-md-end">
                                                                        
                                                                        <div data-toggle="tooltip " className="text-right" > {!data.flagged && <button className="border font-size-3 font-weight-bold text-red px-5 rounded-5  mr-1 border-width-1 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer" data-toggle="tooltip" title="Click to flag." onClick={(e) => toggleModal(data.id)}  >
                                                                            Flag
                              </button>}
                                                                            {data.flagged && <button className="border font-size-3 font-weight-bold text-green-2 px-5 rounded-5  mr-1 border-width-1 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer" data-toggle="tooltip" title="Click to unflag." onClick={() => statusUpdate("unflag", "unflag", data.id)}>
                                                                                UnFlag
                              </button>}</div>
                                                                    </td>

                                                                </tr>)
                                                        })}


                                                    </tbody>
                                                </table>
                                            </div>
                                        </div></div> 
                                </div>  
                            </div>
                        </div>
                    </div>
                </div> 
                <TablePagination range={range} slice={slice} setPage={setPage} page={page} />
                <ModalFlagReason submitflag={submitflag} modalvisible={showModal} togglemodal={toggleModal}    />
            </div>  

           
        </>
    );
};